import React, { useState, createContext, useEffect, useContext, useCallback } from 'react';
import axios from "axios";
import CryptoJS from "crypto-js";
import PreLoader from '../hooks/Others/PreLoader';
import { firebase } from './Storage';
import usePWAStatus from "../hooks/install_PWA/usePWAStatus"
import game_DownloadedTimesUpdater from '../hooks/xGAME/game_Downloaded_Times_updater';
import game_WatchTimesUpdater from '../hooks/xGAME/game_watched_times_updater';
import app_DownloadedTimesUpdater from '../hooks/xAPP/app_Downloaded_Times_updater';
import app_WatchTimesUpdater from '../hooks/xAPP/app_watched_times_updater';
import game_get_Comments from '../hooks/xGAME/game_comments/game_get_Comments';
import game_add_Comment from '../hooks/xGAME/game_comments/game_add_Comment';
import game_delete_Comment from '../hooks/xGAME/game_comments/game_delete_Comment';
import game_edit_Comment from '../hooks/xGAME/game_comments/game_edit_Comment';
import game_like_dislike_Comment from '../hooks/xGAME/game_comments/game_like_dislike_Comment';

import app_get_Comments from '../hooks/xAPP/app_comments/app_get_Comments';
import app_add_Comment from '../hooks/xAPP/app_comments/app_add_Comment';
import app_delete_Comment from '../hooks/xAPP/app_comments/app_delete_Comment';
import app_edit_Comment from '../hooks/xAPP/app_comments/app_edit_Comment';
import app_like_dislike_Comment from '../hooks/xAPP/app_comments/app_like_dislike_Comment';

import { toast } from 'react-toastify';


const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

    const secretKey = process.env.REACT_APP_SECRETKEY;

    const [loading, setLoading] = useState(true)

    const { isPWAInstalled } = usePWAStatus();

    // current user stuff " variables "
    const [user, setUser] = useState(null)
    const [userData, setUserData] = useState([]);
    const [User_IP_Address, setUser_IP_Address] = useState('');
    const [CurrentLanguage, setCurrentLanguage] = useState('AR');
    const [User_Country, setUser_Country] = useState('');
    const [User_OS_Platform, setUser_OS_Platform] = useState('');

    // fetch the ALL Games method
    const [ALL_Games, setALL_Games] = useState([])
    const [gamesWrapper, setGamesWrapper] = useState(12);

    // fetch the ALL Apps method
    const [ALL_Apps, setALL_Apps] = useState([])
    const [appsWrapper, setAppsWrapper] = useState(12);


    const [darkMode, setDarkMode] = useState('dark');

    // change only the darkMode value from localStorage
    const toggleDarkMode = () => {
        try {
            // Retrieve the existing data from localStorage
            const get_darkMode = localStorage.getItem('darkMode');
            if (get_darkMode) {
                // Parse the existing data
                const existingData = JSON.parse(get_darkMode);
                // Update the existing data
                const updatedData = { ...existingData, darkMode: darkMode === 'dark' ? 'light' : 'dark' };
                // Save the updated data to localStorage
                localStorage.setItem('darkMode', JSON.stringify(updatedData));
                // Update the state
                setDarkMode(updatedData.darkMode);
            }
            else {
                // set a new data for darkMode
                localStorage.setItem('darkMode', JSON.stringify({ darkMode: 'dark' }));
                setDarkMode('dark');
            }
        } catch (error) {
            console.error('Error updating darkMode in localStorage:', error);
        }
    };


    useEffect(() => {
        // Retrieve the user data from localStorage
        const getDarkMode_from_localStorage = () => {
            try {
                const get_darkMode = localStorage.getItem('darkMode');

                if (get_darkMode) {
                    setDarkMode(JSON.parse(get_darkMode).darkMode);
                }

            } catch (error) {
                console.error(error);
            }
        };
        getDarkMode_from_localStorage();
    }, [darkMode]);

    // getting the current language from the local storage
    useEffect(() => {
        const currentLanguage = localStorage.getItem('User_Language');
        if (currentLanguage) {
            setCurrentLanguage(currentLanguage);
        }
    }, []);

    // detect the user OS platform
    const UserPlatform = navigator.platform;
    useEffect(() => {
        // if the user is using windows or mac or linux
        if (UserPlatform.includes('Win') || UserPlatform.includes('Mac') || UserPlatform.includes('Linux')) {
            setUser_OS_Platform('Desktop');
        }
        // if the user is using android or ios
        else {
            setUser_OS_Platform('Mobile');
        }
    }, []);

    // ⚠️ Games Stuff 👇🏻 ⚠️
    const gamesWrapper_LoadMore = () => {
        setTimeout(() => {
            setGamesWrapper(gamesWrapper + 12);
        }, 700)
    }

    // ⚠️ Apps Stuff 👇🏻 ⚠️
    const appsWrapper_LoadMore = () => {
        setTimeout(() => {
            setAppsWrapper(appsWrapper + 12);
        }, 700)
    }

    // ⚠️ Current User & Auth Routes 👇🏻 ⚠️

    // check user status method
    const checkUserStatus = async () => {
        setLoading(true);
        setUserData({});

        try {
            await check_User_Auth_Status();
            await getIpAddress();
            await getLocation();

            await fetchALL_Games();
            await fetchALL_Apps();

        }
        catch (error) {
            console.error("Error checking user status:", error);
            // Handle error appropriately
        }
        finally {
            setLoading(false);
        }
    };

    // get IP address 
    const getIpAddress = async () => {
        try {
            const response = await fetch('https://api.ipify.org/?format=json')
                .then(res => res.json())
                .then(data => {
                    return data.ip
                })

            if (response.length > 0) {
                setUser_IP_Address(response)
            }
        }
        catch (error) {
        }
    }

    // get the current user location
    const getLocation = async () => {
        try {
            if (User_IP_Address.length > 0) {
                // get the location using the ip address
                const response = await axios.get(`https://api.myip.com`)
                    .then(res => {
                        return res.data
                    })

                if (response?.country?.length > 0) {
                    setUser_Country(response?.country)
                }
            }

        } catch (error) {
        }
    };


    // check user authentication status
    const check_User_Auth_Status = async () => {
        try {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    setUser(user)
                } else {
                    setUser(null)
                }
            });

        } catch (error) {
            console.error("Error checking user status:", error);
            // Handle error appropriately
        }
    }


    // refresh the user data in real time method
    const refreshUserData = useCallback(async (userInfo) => {
        setUserData({})
        let response_data = null;
        let response_status = null;

        try {
            const refresh_UserData_inBE = await axios.post('https://reto-gaming-mobile-api.vercel.app/api/reto/refresh_Current_User_Data', userInfo)
                .catch((error) => {
                    response_data = error.response.data
                    response_status = error.response.status
                })

            // 200 means the user is created successfully
            if (refresh_UserData_inBE.status === 200) {
                const encrypted_UserData = refresh_UserData_inBE.data.encryptedUserData;

                // Decrypt the received data using the same secret key used for encryption
                const decrypted_UserData_Bytes = CryptoJS.AES.decrypt(
                    encrypted_UserData,
                    secretKey
                );
                const decrypted_UserData = JSON.parse(
                    decrypted_UserData_Bytes.toString(CryptoJS.enc.Utf8)
                );

                console.log("🚀 ~ file: AuthContext.js ~ line 31 ~ refreshUserData done successfully ✅ ")
                // set the user data
                setUserData(decrypted_UserData);
            }


            console.log("🚀 ~ file: AuthContext.js ~ line 31 ~ refreshUserData ")
        }
        catch (error) {
            console.log("❌ Firebase Service -> refreshUserData -> error :   ", error)
            Alert.alert(
                defaultSttings.defaultLanguage.startsWith('ar')
                    ? 'خطأ'
                    : 'Error',
                `❌ status : ${response_status}` + '\n' + `${response_data}`,
            )
        }
    }, []);

    // login method
    const loginUser = async (userInfo) => {

        let response_data = null;
        let response_status = null;

        let post_data = {
            ...userInfo,
        }

        try {

            const loginUser_inBE = await axios.post('https://reto-gaming-mobile-api.vercel.app/api/reto/login_Current_User', post_data)
                .catch((error) => {
                    response_data = error.response.data
                    response_status = error.response.status
                })

            // 200 means the user is created successfully
            if (loginUser_inBE.status === 200) {
                console.log('logged in successfully ✅');

                const loginResponse = await firebase.auth().signInWithEmailAndPassword(userInfo.email, userInfo.password);
                setLoading(true)

                // send email verification
                if (!loginResponse?.user.emailVerified) await firebase.auth().currentUser.sendEmailVerification()

                if (loginResponse.user) {
                    console.log('🚀 file: AuthContext.js ~ line 349 ~ registerUser ~  logged in successfully ✅');
                }
                else {
                    console.error('User login failed:', loginResponse);
                }

                await checkUserStatus()

            }
            else {
                console.log('User login failed:', loginUser_inBE);
                return;
            }

            console.log("🚀 ~ file: AuthContext.js ~ line 191 ~ loginUser ~ setUserData done ✅ ")

        }
        catch (error) {
            if (response_status !== null && response_data !== null) {
                Alert.alert(
                    defaultSttings.defaultLanguage.startsWith('ar')
                        ? 'خطأ'
                        : 'Error',
                    `❌ status : ${response_status}` + '\n' + `${response_data}`,
                )

                console.log("❌ status : ", response_status)
                console.log("❌ data : ", response_data)
            }
        }
        finally {
            setLoading(false);
        }
    };

    // logout method
    const logoutUser = async () => {
        let response_data = null;
        let response_status = null;

        let post_data = {
            uid: firebase.auth()?.currentUser?.uid || userData[0]?.User_uid,
        }

        try {

            const logoutUser_inBE = await axios.post('https://reto-gaming-mobile-api.vercel.app/api/reto/logout_Current_User', post_data)
                .catch((error) => {
                    response_data = error.response.data
                    response_status = error.response.status
                })

            // 200 means the user is created successfully
            if (logoutUser_inBE.status === 200) {
                await AsyncStorage.removeItem('authCredentials');
                await firebase.auth().signOut()
                    .then(() => {
                        setUser(null)
                        setUserData(null)
                    })
            }
            else {
                console.log('User logout failed:', logoutUser_inBE);
                return;
            }

            console.log("🚀 ~ file: AuthContext.js ~ line 73 ~ logoutUser ~ user logged out")

        }
        catch (error) {
            console.log("❌ Firebase Service -> logoutUser -> error :   ", error)
            Alert.alert(
                defaultSttings.defaultLanguage.startsWith('ar')
                    ? 'خطأ'
                    : 'Error',
                `❌ status : ${response_status}` + '\n' + `${response_data}`,
            )
        }
        finally {
            setLoading(false);
        }
    };

    // Delete user from database and its account method
    const deleteUser = async () => {
        const currentUser_uid = firebase.auth().currentUser.uid

        let response_data = null;
        let response_status = null;

        try {
            const userInfo = {
                User_uid: firebase.auth()?.currentUser?.uid || userData[0]?.User_uid,
            }

            const deleteUser_inBE = await axios.post('https://reto-gaming-mobile-api.vercel.app/api/reto/delete_Current_User', userInfo)
                .catch((error) => {
                    response_data = error.response.data
                    response_status = error.response.status
                })

            // 200 means the user is created successfully
            if (deleteUser_inBE.status === 200) {
                // delete the user profile picture from the storage
                // first check if it in the storage or not using the user uid
                let imageRef = firebase
                    ?.storage()
                    ?.ref(`user_profile_pictures/${firebase.auth().currentUser.uid}/${firebase.auth().currentUser.uid}`)
                    ?.getDownloadURL()

                if (imageRef !== null || imageRef !== undefined || imageRef !== '') {
                    await firebase
                        ?.storage()
                        ?.ref(`user_profile_pictures/${currentUser_uid}`)
                        ?.delete()
                        .catch((error) => {
                            console.log("❌ Firebase Service -> deleteUser -> delete profile picture -> error :   ", error)
                        })
                }
                else {
                    console.log("🚀 ~ file: AuthContext.js ~ line 127 ~ deleteUser ~ no profile picture to delete")
                }

                await firebase.auth().signOut()
                    .then(() => {
                        setUser(null)
                        setUserData(null)
                    })
            }
            else {
                console.log('User registration failed:', deleteUser_inBE);
                return;
            }

            await AsyncStorage.removeItem('authCredentials');
            await AsyncStorage.removeItem('darkMode');

            console.log("🚀 ~ file: AuthContext.js ~ line 159 ~ deleteUser ~ user deleted")
            setUser(null)
            setUserData(null)

            Alert.alert(
                defaultSttings.defaultLanguage.startsWith('ar')
                    ? '🥲 نتمنى بأن نراك مرة أخرى'
                    : 'We hope to see you again 🥲',
                defaultSttings.defaultLanguage.startsWith('ar')
                    ? 'تم حذف الحساب بنجاح'
                    : 'Account deleted successfully',
                [
                    {
                        text: defaultSttings.defaultLanguage.startsWith('ar')
                            ? 'اوك'
                            : 'OK',
                        onPress: () => console.log("🚀 ~ file: AuthContext.js ~ line 171 ~ deleteUser ~ OK Pressed"),
                        style: "cancel"
                    },
                ],
                { cancelable: false }
            );
        }
        catch (error) {
            console.log("❌ Firebase Service -> deleteUser -> error :   ", error)
            Alert.alert(
                defaultSttings.defaultLanguage.startsWith('ar')
                    ? 'خطأ'
                    : 'Error',
                defaultSttings.defaultLanguage.startsWith('ar')
                    ? 'حدث خطأ أثناء حذف الحساب'
                    : 'An error occurred while deleting the account'
            )
        }
        finally {
            setLoading(false);
        }
    };

    // register method
    const registerUser = async (userInfo) => {

        let response_data = null;
        let response_status = null;

        try {

            let post_data = {
                ...userInfo,
                User_Language: defaultSttings.defaultLanguage,
            }

            const createUserinBE = await axios.post('https://reto-gaming-mobile-api.vercel.app/api/reto/create_New_User', post_data)
                .catch((error) => {
                    response_data = error.response.data
                    response_status = error.response.status
                })

            // 200 means the user is created successfully
            if (createUserinBE.status === 200) {

                setLoading(true)
                const loginResponse = await firebase.auth().signInWithEmailAndPassword(userInfo.email, userInfo.password);

                // send email verification
                await firebase.auth().currentUser.sendEmailVerification()

                // Save the modified data back to AsyncStorage
                await AsyncStorage.setItem('darkMode', JSON.stringify({ darkMode: true }));


                if (loginResponse.user) {
                    console.log('🚀 file: AuthContext.js ~ line 349 ~ registerUser ~ user created & logged in successfully ✅');
                    // You can handle the authenticated user state here
                }
                else {
                    console.error('User login failed:', loginResponse);
                }

                await checkUserStatus()
            }
            else {
                console.log('User registration failed:', createUserinBE);
                return;
            }


            console.log("🚀 ~ file: AuthContext.js ~ line 359 ~ registerUser ~ setUserData done ✅ ")

        }
        catch (error) {
            Alert.alert(
                defaultSttings.defaultLanguage.startsWith('ar')
                    ? 'خطأ'
                    : 'Error',
                `❌ status : ${response_status}` + '\n' + `${response_data}`,
            )

            console.log("❌ status : ", response_status)
            console.log("❌ data : ", response_data)
        }
        finally {
            setLoading(false);
        }
    };

    // forgot password method
    const forgotPassword = async (userInfo) => {

        try {

            const forgotPassword_inBE = await axios.post('https://reto-gaming-mobile-api.vercel.app/api/reto/forgot_Password', userInfo)

            // 200 means the user is created successfully
            if (forgotPassword_inBE.status === 200) {
                await firebase.auth().sendPasswordResetEmail(userInfo.email);

                console.log('🚀 file: AuthContext.js ~ line 432 ~ forgotPassword ~ sent reset password link to the user ✅');
                // You can handle the authenticated user state here
            }
            else {
                console.log('User forgot password failed:', forgotPassword_inBE);
                return;
            }
        }
        catch (error) {
            console.log("❌ file: AuthContext.js ~ line 408 ~ forgotPassword ~ couldn't send the link ~ error :   ", error)
        }
    };



    // ⚠️ others routes 👇🏻 ⚠️

    // fetch the ALL Games method
    const fetchALL_Games = async () => {

        try {
            const fetchALL_Games_inBE = await axios.post("https://arab2games-games-backend.vercel.app/api/arab2games/ALL_games");

            // 200 means the method is done successfully
            if (fetchALL_Games_inBE.status === 200) {
                const encrypted_ALL_Games = fetchALL_Games_inBE.data.gamesArray
                    ? fetchALL_Games_inBE.data.gamesArray : fetchALL_Games_inBE.data.ALL_games_encryptedData;

                const decryptedBytes = CryptoJS.AES.decrypt(
                    encrypted_ALL_Games,
                    secretKey // Replace with your secret key
                );
                const decrypted_ALL_Games = JSON.parse(
                    decryptedBytes.toString(CryptoJS.enc.Utf8)
                );
                // set the ALL_games data
                setALL_Games(decrypted_ALL_Games);
            }
            else {
                console.log('❌ file:  fetch ALL Games ~ error', fetchALL_Games_inBE);
                return;
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // fetch the ALL Games method
    const fetchALL_Apps = async () => {

        try {
            const fetchALL_Apps_inBE = await axios.get("https://arab2games-apps-backend.vercel.app/api/arab2games/ALL_apps");

            // 200 means the method is done successfully
            if (fetchALL_Apps_inBE.status === 200) {
                const encrypted_ALL_Apps = fetchALL_Apps_inBE.data.appsArray
                    ? fetchALL_Apps_inBE.data.appsArray : fetchALL_Apps_inBE.data.ALL_apps_encryptedData;

                const decryptedBytes = CryptoJS.AES.decrypt(
                    encrypted_ALL_Apps,
                    `${secretKey}` // Replace with your secret key
                );
                const decrypted_ALL_Apps = JSON.parse(
                    decryptedBytes.toString(CryptoJS.enc.Utf8)
                );

                // set the ALL_apps data
                setALL_Apps(decrypted_ALL_Apps);

            }
            else {
                console.log('❌ fetch ALL Apps ~ error', fetchALL_Apps_inBE);
                return;
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // game_Downloaded_Times_updater method
    const game_Downloaded_Times_updater = useCallback(async (gameData) => {
        try {
            const response = await game_DownloadedTimesUpdater(gameData)

            if (response === 'done' || response === true) {
                gameData.game_Download_Torrent_Link
                    ? window.location.href = gameData.game_Download_Torrent_Link
                    : toast.error('حدث خطأ أثناء جذب التورنت');
            }
            else {
                toast.error('حدث خطأ أثناء جذب التورنت');
            }
        }
        catch (error) {
            console.log("❌ couldn't update the download times ~ error :   ", error)
            return false;
        }
    }, []);

    // game_watched_times_updater method
    const game_watched_times_updater = useCallback(async ({ All_gameData, User_IP_Address }) => {
        try {
            await game_WatchTimesUpdater({ All_gameData, User_IP_Address })
        }
        catch (error) {
            console.log("❌ couldn't update the watched times ~ error :   ", error)
            return false;
        }
    }, []);

    // app_Downloaded_Times_updater method
    const app_Downloaded_Times_updater = useCallback(async (appData) => {
        try {
            const response = await app_DownloadedTimesUpdater(appData)

            if (response === 'done' || response === true) {
                appData.app_Download_Torrent_Link
                    ? window.location.href = appData.app_Download_Torrent_Link
                    : toast.error('حدث خطأ أثناء جذب التورنت');
            }
            else {
                toast.error('حدث خطأ أثناء جذب التورنت');
            }
        }
        catch (error) {
            console.log("❌ couldn't update the download times ~ error :   ", error)
            return false;
        }
    }, []);

    // app_watched_times_updater method
    const app_watched_times_updater = useCallback(async ({ All_appData, User_IP_Address }) => {
        try {
            await app_WatchTimesUpdater({ All_appData, User_IP_Address })
        }
        catch (error) {
            console.log("❌ couldn't update the watched times ~ error :   ", error)
            return false;
        }
    }, []);

    // get the current game comments
    const getGameComments = async (gameData) => {

        try {
            const response = await game_get_Comments(gameData)

            if (response) {
                const encrypted_gameComments = response;

                const decryptedBytes = CryptoJS.AES.decrypt(
                    encrypted_gameComments,
                    secretKey // Replace with your secret key
                );
                const decrypted_gameComments = JSON.parse(
                    decryptedBytes.toString(CryptoJS.enc.Utf8)
                );
                return decrypted_gameComments;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the game comments ~ error :   ", error)
            return false;
        }
    }

    // add new comment to the current game
    const addGameComment = async ({ gameData, comment_values }) => {

        try {
            const response = await game_add_Comment({ gameData, comment_values })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the game comments ~ error :   ", error)
            return false;
        }
    }

    // delete a comment from the current game
    const deleteGameComment = async ({ gameData, specific_comment }) => {

        try {
            const response = await game_delete_Comment({ gameData, specific_comment, user: user })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the game comments ~ error :   ", error)
            return false;
        }
    }

    // edit a comment from the current game
    const editGameComment = async ({ gameData, specific_comment }) => {

        try {
            const response = await game_edit_Comment({ gameData, specific_comment, user: user })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the game comments ~ ")
            return false;
        }
    }

    // like dislike a comment from the current game
    const like_dislike_GameComment = async ({ gameData, specific_comment }) => {

        try {
            const response = await game_like_dislike_Comment({ gameData, specific_comment, user: user })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the game comments ~ ")
            return false;
        }
    }

    // get the current app comments
    const getAppComments = async (appData) => {

        try {
            const response = await app_get_Comments(appData)

            if (response) {
                const encrypted_appComments = response;

                const decryptedBytes = CryptoJS.AES.decrypt(
                    encrypted_appComments,
                    secretKey // Replace with your secret key
                );
                const decrypted_appComments = JSON.parse(
                    decryptedBytes.toString(CryptoJS.enc.Utf8)
                );
                return decrypted_appComments;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the app comments ~ error :   ", error)
            return false;
        }
    }

    // add new comment to the current app
    const addAppComment = async ({ appData, comment_values }) => {

        try {
            const response = await app_add_Comment({ appData, comment_values })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the app comments ~ error :   ", error)
            return false;
        }
    }

    // delete a comment from the current app
    const deleteAppComment = async ({ appData, specific_comment }) => {

        try {
            const response = await app_delete_Comment({ appData, specific_comment, user: user })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the app comments ~ error :   ", error)
            return false;
        }
    }

    // edit a comment from the current app
    const editAppComment = async ({ appData, specific_comment }) => {

        try {
            const response = await app_edit_Comment({ appData, specific_comment, user: user })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the app comments ~ ")
            return false;
        }
    }

    // like dislike a comment from the current app
    const like_dislike_AppComment = async ({ appData, specific_comment }) => {

        try {
            const response = await app_like_dislike_Comment({ appData, specific_comment, user: user })

            if (response === true || response === 'done') {
                return true;
            }
            else {
                return false;
            }
        }
        catch (error) {
            console.log("❌ couldn't get the app comments ~ ")
            return false;
        }
    }


    useEffect(() => {
        checkUserStatus()
    }, []);

    const contextData = {
        // methods and variables for the current user Stuff
        user,
        userData,
        User_IP_Address,
        User_Country,
        CurrentLanguage,
        User_OS_Platform,
        forgotPassword,

        // dark mode stuff
        darkMode,
        toggleDarkMode,

        // PWA Status
        isPWAInstalled,

        // fetch the ALL players and Games methods
        ALL_Games,
        gamesWrapper,
        gamesWrapper_LoadMore,
        fetchALL_Games,
        getGameComments,
        addGameComment,
        deleteGameComment,
        editGameComment,
        like_dislike_GameComment,

        ALL_Apps,
        appsWrapper,
        appsWrapper_LoadMore,
        fetchALL_Apps,
        getAppComments,
        addAppComment,
        deleteAppComment,
        editAppComment,
        like_dislike_AppComment,


        game_Downloaded_Times_updater,
        game_watched_times_updater,
        app_Downloaded_Times_updater,
        app_watched_times_updater,

    }

    return (
        <AuthContext.Provider value={contextData}>
            {loading === true ? <PreLoader load={loading} darkMode={darkMode} /> : children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => { return useContext(AuthContext) }

export default AuthContext