import React, { useEffect, useState } from 'react'
import UserScreen from './screens/UserScreen'
import UserScreen2 from './screens/UserScreen2'
import { useAuth } from './xFireBase/AuthContext';


const AuthNavigation = () => {
  const { user } = useAuth()
  const [authenticated, setAuthenticated] = useState(false);


  useEffect(() => {
    if (user) {
      console.log('user')
      setAuthenticated(true)
    }
    else {
      console.log('dffdfdfdf')
      setAuthenticated(false)
    }
  }, [user])

  return <>{authenticated ?
    <UserScreen />
    : <UserScreen2 />}</>
}

export default AuthNavigation


