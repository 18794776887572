import { useState, useEffect } from "react";

const usePWAStatus = () => {
    const [isPWAInstalled, setIsPWAInstalled] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null); // To save the install prompt event

    const checkPWAStatus = () => {
        const isStandalone =
            window.matchMedia("(display-mode: standalone)").matches ||
            window.navigator.standalone;

        setIsPWAInstalled(isStandalone);
    };

    const promptInstall = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Show the install prompt
            const choiceResult = await deferredPrompt.userChoice;
            if (choiceResult.outcome === "accepted") {
                console.log("PWA installation accepted");
                setIsPWAInstalled(true); // Update state
            } else {
                console.log("PWA installation dismissed");
            }
            setDeferredPrompt(null); // Clear the deferred prompt
        }
    };

    useEffect(() => {
        checkPWAStatus();

        // Listen for PWA installation events
        window.addEventListener("appinstalled", () => {
            console.log("PWA installed");
            setIsPWAInstalled(true);
        });

        // Listen for the beforeinstallprompt event
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault(); // Prevent the default install prompt
            setDeferredPrompt(e); // Save the event to trigger later
        };

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        // Cleanup event listeners on component unmount
        return () => {
            window.removeEventListener("appinstalled", checkPWAStatus);
            window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
        };
    }, []);

    return { isPWAInstalled, promptInstall };
};

export default usePWAStatus;
